import React from 'react'
import './style.less';
import { Layout, Row, Col } from 'antd';
import FootNav from "../FootNav";
const { Footer } = Layout;

class Foot extends React.Component {

    render(){

        return (
            <Footer>
                <Row>
                    <Col span={24}>
                        <FootNav />
                    </Col>
                </Row>
            </Footer>
        );
    }
}

export default Foot
