import React from 'react'
import './style.less';
import { Layout, Row, Col } from 'antd';
import { Link } from 'gatsby';
import TopNav from "../TopNav";

const { Header } = Layout;

class Head extends React.Component {

    render(){

        return (
            <Header style={{ width: "100%"}}>
                <Row style={{position: 'relative'}}>
                    <Col span={12} style={{padding: 24}}>
                        <Link to={'/'}>
                            <img src={"/home-logo.png"} style={{ width: 100, position: 'absolute', top: -20, left: 20 }} />
                        </Link>
                    </Col>
                    <Col span={12}>
                        <TopNav />
                    </Col>
                </Row>
            </Header>
        );
    }
}

export default Head
