import React from 'react';
import './style.less';
import {Col, Menu, Button, Row, Icon} from "antd";
import Link from "../Link";
import { StaticQuery, graphql, navigate } from "gatsby";
import { isLoggedIn, logout } from "../../services/auth";

class TopNav extends React.Component {
    state = { open : false };

    openMobileNav = () => {
        this.setState({
            open : true,
        });
    };

    handleLogout = () => {
        logout(() => {
            navigate('/');
            this.setState({
                open : false,
            });
        })
    };

    closeMobileNav = () => {
        this.setState({
            open : false,
        });
    };

    render() {
        const isPartiallyActive = ({isPartiallyCurrent}) => {
            return isPartiallyCurrent
                ? { className: "activeLink" }
                : null
        };

        return (
            <React.Fragment>
                <Col xs={{span:24}} className="text-right">
                    <Button className="menu-trigger open" onClick={this.openMobileNav}><Icon type="menu" style={{fontSize: 32, marginRight: 12}} /></Button>
                </Col>

                <div className="mobile-navigation" open={this.state.open}>
                    <Row type="flex" align="bottom">
                        <Col xs={{span:24}} className="text-right">
                            <Button className="menu-trigger close" onClick={this.closeMobileNav}>Close <span className="icon" /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={{span:24}}>


                            {isLoggedIn() ?
                                <Menu mode="vertical">
                                    <Menu.Item key="profile"  onClick={this.closeMobileNav}><Link to={'/app/profile'}><Icon type="user" className="navIcon" /> My Account</Link></Menu.Item>
                                    <Menu.Item key="reward" onClick={this.closeMobileNav}><Link to={'/app/rewarding-excellence'}><Icon type="right" className="navIcon" /> Rewarding Excellence</Link></Menu.Item>
                                    <Menu.Item key="unlock" onClick={this.closeMobileNav}><Link to={'/app/unlock-value'}><Icon type="right" className="navIcon" /> Unlocking Value</Link></Menu.Item>
                                    <Menu.Item key="account" onClick={this.closeMobileNav}><Link to={'/app/account-manager'}><Icon type="right" className="navIcon" /> Account Manager</Link></Menu.Item>
                                    <Menu.Item key="auth"><a onClick={this.handleLogout}><Icon type="right" className="navIcon" /> Logout</a></Menu.Item>
                                </Menu>
                                :
                                <Menu mode="vertical">
                                    <Menu.Item key="auth" onClick={this.closeMobileNav}><Link to="/app/login" getProps={isPartiallyActive}><Icon type="right" className="navIcon" /> Login</Link></Menu.Item>
                                </Menu>
                            }
                        </Col>
                    </Row>
                </div>
            </React.Fragment>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query NavItemLinks {
                allWordpressWpApiMenusMenusItems (filter: {slug: {eq:"header-menu"}}){
                    edges {
                        node {
                            id
                            slug
                            description
                            wordpress_id
                            items {
                                title
                                url
                                target
                                classes
                                order
                                wordpress_id
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <TopNav links={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
        )}
    />
)
