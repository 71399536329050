import React from 'react';
import { Col } from "antd";
import Link from "../Link";
import { StaticQuery, graphql } from "gatsby";
import './style.less';

class FootNav extends React.Component {

    render() {
        const isPartiallyActive = ({isPartiallyCurrent}) => {
            return isPartiallyCurrent
                ? { className: "activeLink" }
                : null
        };

        return (
            <React.Fragment>
                <Col span={24}>
                        <ul id="footerNav">
                        {this.props.links.map((link) => {
                            // add a trailing slash
                            if (link.url.substr(-1) !== '/') link.url += '/';
                            return <li key={link.title}><Link to={link.url} getProps={isPartiallyActive}>{link.title}</Link></li>
                        })}
                        </ul>
                </Col>
            </React.Fragment>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query FooterNavItemLinks {
                allWordpressWpApiMenusMenusItems (filter: {slug: {eq:"footer-menu"}}){
                    edges {
                        node {
                            id
                            slug
                            description
                            wordpress_id
                            items {
                                title
                                url
                                target
                                classes
                                order
                                wordpress_id
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <FootNav links={data.allWordpressWpApiMenusMenusItems.edges[0].node.items} />
        )}
    />
)
