import React, {Component} from "react";
import { Helmet } from "react-helmet";
import site from "../../../data/site.yaml";

class MetaData extends Component {

    getSchema(){
        const schemaOrgJSONLD = [
            {
                "@context": "http://schema.org",
                "@type": "LocalBusiness",
                name: site.business_name,
                legalName: site.legal_name,
                openingHours: site.opening_hours,
                geo: {
                    "@type": "GeoCoordinates",
                    latitude: site.latitude,
                    longitude: site.longitude,
                },
                description: "",
                slogan: "",
                address: {
                    "@type": "PostalAddress",
                    addressLocality: site.locality,
                    addressRegion: site.region,
                    postalCode: site.postcode,
                    streetAddress: site.address
                },
                telephone: site.telephone,
                email: site.email,
                url: site.domain,
            }
        ];

        // Add any custom schema objects being passed through from the page
        if (this.props.schema) {
            this.props.schema.map(item => {
                return schemaOrgJSONLD.push(item);
            });
        }
        return JSON.stringify(schemaOrgJSONLD);
    }

    render() {

            return (
            <Helmet
                encodeSpecialCharacters={false}
                titleTemplate={site.title_template}
            >
                <title>{this.props.title}</title>
                <html lang="en" />
                <meta name="description" content={this.props.description} />

                { this.props.canonical !== undefined  ?  <link rel="canonical" href={site.domain + '/' + this.props.canonical} /> : null }

                <meta name="docsearch:version" content="2.0" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />

                {/* Schema */}
                <script type="application/ld+json">
                    {this.getSchema(site.domain)}
                </script>

                {/* OpenGraph */}
                { this.props.og_title ? <meta property="og:url" content={site.domain + this.props.path} /> : null }
                { this.props.og_title ? <meta property="og:type" content="website" /> : null }
                { this.props.og_title ? <meta property="og:locale" content="en" /> : null }
                { this.props.og_title ? <meta property="og:site_name" content={site.title} /> : null }
                { this.props.og_title ? <meta property="og:title" content={this.props.og_title} /> : null }
                { this.props.og_description  ? <meta property="og:description" content={this.props.og_description} /> : null }
                { this.props.og_image  ? <meta property="og:image" content={site.domain + this.props.og_image} /> : null }
                { this.props.og_image  ? <meta property="og:image:width" content="512" /> : null }
                { this.props.og_image  ? <meta property="og:image:height" content="512" /> : null }

                {/* Twitter Card */}
                { this.props.twitter_title ? <meta name="twitter:site" content={site.twitter} /> : null }
                { this.props.twitter_title ? <meta name="twitter:title" content={this.props.twitter_title} /> : null }
                { this.props.twitter_title ? <meta name="twitter:description" content={this.props.twitter_description} /> : null }
                { this.props.twitter_title ? <meta name="twitter:card" content={this.props.twitter_card_type} /> : null }
                { this.props.twitter_image ? <meta name="twitter:image" content={site.domain + this.props.twitter_image} /> : null }

            </Helmet>
        )
    }
}

export default MetaData
