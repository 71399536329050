import axios from 'axios';
import crypto from 'crypto-js';
import site from '../../data/site.yaml';

const isBrowser = typeof window !== `undefined`;

const getUser = () => {
    try {
        const obj = crypto.AES.decrypt(window.localStorage.eiu, process.env.KEY);
        const originalText = obj.toString(crypto.enc.Utf8);
        if (window.localStorage.eiu) {
            return JSON.parse(originalText)
        } else {
            return [];
        }
    } catch (err) {
        return []
    }
};

const setUser = user => {
    const userJSON = JSON.stringify(user);
    window.localStorage.eiu = crypto.AES.encrypt(userJSON, process.env.KEY).toString();
};

export const handleLogin = ({ email, password }, callback) => {
    if (!isBrowser) return false;

    axios
        .post(`${site.content_api_source}wp-json/jwt-auth/v1/token`, {
            username: email,
            password: password
        })
        .then(response => {
            if(response.data.token) {
                const userData = {
                    jwt: response.data.token,
                    username: response.data.user_display_name
                };
                setUser(userData);
            }
            return response;
        })
        .then(response => {
            setTimeout(() => {
                if(response.data.token) {
                    callback(false, true);
                } else {
                    callback(true, false);
                }
            }, 1000)

        })
        .catch(error => {
            // Handle error.
            callback(true);
        });
};

export const handleForgotPassword = ({ email }, callback) => {
    if (!isBrowser) return false;

    axios
        .post(`${site.content_api_source}wp-json/brew/v1/user/forgotpassword/`, {
            email: email
        })
        .then((res) => {
            console.log(res)
            callback(true);
        })
        .catch(() => {
            // Handle error, we always assume success to hide invalid emails from people
            callback(true);
        });
};

export const handleResetPassword = ({ code, password, email }, callback) => {
    if (!isBrowser) return false;
    axios
        .post(`${site.content_api_source}wp-json/brew/v1/user/resetpassword`, {
            resetkey: code,
            password: password,
            email: email
        })
        .then(response => {
            // Handle success.
            callback(true);
        })
        .catch(error => {
            // Handle error.
            callback(true, false);
        });
};

export const isLoggedIn = () => {
    if (!isBrowser) return false;
    const user = getUser();
    return !!user.jwt;
};

export const getCurrentUser = () => isBrowser && getUser();

export const logout = callback => {
    if (!isBrowser) return;
    setUser({});
    if(callback) callback();
};
